
import { defineComponent, reactive, ref } from "vue";
import {
  getChargePlan,
  enableChargePlan,
  disableChargePlan,
} from "@/core/services/api/chargePlan";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {
  statusMap,
  targetTypeMap,
} from "@/core/services/responsesModel/chargePlan";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { typeMap } from "@/core/services/responsesModel/facilityExtend";
import { filterTableDataBySearch } from "@/core/utils/tableSearch";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const router = useRouter();
    const search = ref("");
    const facilities: any = reactive([]);
    const tableHeader = ref([
      {
        name: "標題",
        key: "title",
        sortable: true,
      },
      {
        name: "適用類型",
        key: "targetType",
        sortable: true,
      },
      {
        name: "適用對象",
        key: "target",
        sortable: true,
      },
      {
        name: "狀態",
        key: "status",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const chargePlan: any = reactive([]);
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const currentItem: any = reactive({});

    // 畫面跳轉至新增價格方案
    const toAddChargePlan = () => {
      router.push({ name: "addChargePlan" });
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    // 設置設施
    const setFacilities = async () => {
      facilities.splice(
        0,
        facilities.length,
        ...(await getFacilityBySpace(currentSpaceGuid!))
      );
      console.log("facilities", facilities);
    };

    // 根據guid取得設施
    const getFacilityByGuid = (guid) => {
      let facility = facilities.filter((o) => o.facility.guid == guid)[0]
        .facility;
      return facility;
    };

    // 設置列表
    const setTableData = () => {
      tableData.splice(0);
      for (const item of chargePlan) {
        tableData.push({
          title: item.title,
          guid: item.guid,
          targetType: typeMap[getFacilityByGuid(item.target).type],
          //   targetType: targetTypeMap[item.targetType],
          target: getFacilityByGuid(item.target).name,
          status: statusMap[item.status],
        });
      }
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    // 設置價格方案
    const setChargePlan = async () => {
      chargePlan.splice(
        0,
        chargePlan.length,
        ...(await getChargePlan(currentSpaceGuid!))
      );
      console.log("chargePlan", chargePlan);
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const toTimeRuleSetUp = () => {
      router.push({ path: `timeRuleSetUp/${currentItem.guid}` });
    };

    const toChargePlanSetUp = () => {
      router.push({ path: `chargePlanSetUp/${currentItem.guid}` });
    };

    // 啟用價格方案
    const enableCurrentChargePlan = async (guid) => {
      enableChargePlan(guid!)
        .then(() => {
          Swal.fire("啟用成功!", "", "success");
          init();
        })
        .catch((err) => {
          console.log("err", err);
          Swal.fire("啟用失敗!", "", "error");
        });
    };

    // 停用價格方案
    const disableCurrentChargePlan = async (guid) => {
      disableChargePlan(guid!)
        .then(() => {
          Swal.fire("停用成功!", "", "success");
          init();
        })
        .catch((err) => {
          console.log("err", err);
          Swal.fire("停用失敗!", "", "error");
        });
    };
    const init = async () => {
      await setFacilities();
      await setChargePlan();
      setTableData();
    };
    init();

    return {
      tableHeader,
      tableData,
      search,
      handleFilterTableDataBySearch,
      toAddChargePlan,
      setCurrentItem,
      toTimeRuleSetUp,
      toChargePlanSetUp,
      enableCurrentChargePlan,
      disableCurrentChargePlan,
    };
  },
});
