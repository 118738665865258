import ApiService from "@/core/services/ApiService";

// 取得空間價格方案
export function getChargePlan(spaceGuid: string): Promise<Array<any>> {
  return new Promise((resolve, reject) => {
    ApiService.get(`Spaces/${spaceGuid}/TimeChargePlans`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 取得指定價格方案
export function getTargetChargePlan(timeChargePlanGuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`TimeChargePlans/${timeChargePlanGuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 取得價格方案時間規則
export function getChargePlanTimeRule(timeChargePlanGuid): Promise<Array<any>> {
  return new Promise((resolve, reject) => {
    ApiService.get(`TimeChargePlans/${timeChargePlanGuid}/BaseTimeRules`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 新增價格方案時間規則
export function addChargePlanTimeRule(
  timeChargePlanGuid,
  request
): Promise<Array<any>> {
  return new Promise((resolve, reject) => {
    ApiService.post(
      `TimeChargePlans/${timeChargePlanGuid}/BaseTimeRules`,
      request
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 更新時間規則
export function updateTimeRule(
  timeChargePlanGuid,
  request
): Promise<Array<any>> {
  return new Promise((resolve, reject) => {
    ApiService.put(`TimeChargePlans/${timeChargePlanGuid}/BaseTimeRules`, request)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 新增價格方案
export function addFacilityChargePlan(facilityGuid, request): Promise<any> {
  return new Promise((resolve, reject) => {
    ApiService.post(`Facilities/${facilityGuid}/TimeChargePlans`, request)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 更新價格方案
export function updateFacilityChargePlan(
  facilityGuid,
  timeChargePlanGuid,
  request
): Promise<any> {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `TimeChargePlans/${timeChargePlanGuid}`,
      request
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 啟用價格方案
export function enableChargePlan(timeChargePlanGuid): Promise<any> {
  return new Promise((resolve, reject) => {
    ApiService.patch(`TimeChargePlans/${timeChargePlanGuid}/Enable`, {})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 停用價格方案
export function disableChargePlan(timeChargePlanGuid): Promise<any> {
  return new Promise((resolve, reject) => {
    ApiService.patch(`TimeChargePlans/${timeChargePlanGuid}/Disable`, {})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
